import * as React from "react";
import { useState } from "react";
import { container, homePage } from "../layout.module.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, Button } from "@mui/material";
import {navigate} from 'gatsby';

const IndexPage = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className={container}>
      <p className={homePage} style={{ paddingTop: `15vw` }}>PRIYANKA KOLLI</p>
      <p style={{ fontSize: `20px`, justifyContent: "center" }}>and</p>
      <p className={homePage}>VINEET NADELLA</p>
      <TextField
        onChange={(e) => setPassword(e.target.value)}
        style={{
          fontSize: `20px`,
          width: `300px`,
          display: "flex",
          textAlign: "left",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        id="outlined-password-input"
        value={password}
        label="Password"
        type={showPassword ? "text" : "password"}
        autoComplete="current-password"
        required={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
      <br />
      <Button
        style={{
          fontSize: `18px`,
          borderRadius: "4px",
          fontWeight: `bold`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "auto",
          marginRight: "auto",
          width: `200px`,
          textTransform: "none",
          fontFamily: "Libre Baskerville",
          backgroundColor: "#034488",
        }}
        onClick={() => {
          if (password === "greece2025") {
            console.log("Passed");
            setIsLoggedIn(true);
            // navigate("home");
            navigate("../home");
          } else {
            console.log("Fail");
            // Access the element by its ID
            var element = document.getElementById("outlined-password-input");

            // Change the style properties
            element.style.border = "1px solid red";
            element.style.borderRadius = "4px";
          }
        }}
        variant="contained"
      >
        Submit
      </Button>
    </div>
  );
};

export default IndexPage;